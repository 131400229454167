export const API_URL = process.env.REACT_APP_API_URL || ''

export const SET_ZOOM = 'main/setZoom';
export const SET_AREAS_DATA = 'main/setAreasData';
export const SET_COMPANIES = 'main/setCompanies';
export const ADD_POINT = 'main/addPoint';
export const RESET_POINT = 'main/resetPoint';
export const ADD_POINT_DATA = 'main/addPointData';
export const SET_POINTS_DATA = 'main/setPointsData';
export const ADD_AREA_DATA = 'main/addAreaData';
export const REMOVE_AREA_DATA = 'main/removeAreaData';
export const UPDATE_AREA_DATA = 'main/updateAreaData';
export const SET_SELECTED_AREA_ID = 'main/setSelectedAreaId';
export const SET_SELECTED_AREA_DATA = 'main/setSelectedAreaData';
export const SET_HOVERED_AREA_ID = 'main/setHoveredAreaId';
export const TOGGLE_MODE = 'main/toggleMode';
export const SET_TASK = 'main/setTask';
export const SET_SUCCESSED_ANSWERS = 'main/setSuccessedAnswers'
export const SET_FAILED_ANSWERS = 'main/setFailedAnswers'
export const SET_GAME = 'main/setGame';
export const SET_AREAS = 'main/setAreas';
export const SET_LAKES = 'main/setLakes';
export const SET_RIVERS = 'main/setRivers';
export const SET_BORDERS = 'main/setBorders';
export const SET_ON_SELECT = 'main/setOnSelect';
export const SET_USERS = 'main/setUsers';

export const MAPBOX_ACCESS_TOKEN = "pk.eyJ1IjoiZmFld2ZhZXdmIiwiYSI6ImNsNHkyczJveTA5bXgzY282Y244NzZldTQifQ.e_xurgA24psxPjnR6sBHZA"

export const DEBOUNCE_TIME = 300

export const NAVIGATION_COLLAPSE = "navigation-collapse"
export const FILTER_START_DATE = "filters.startDate"
export const FILTER_CITY = "filters.city"
export const FILTER_INFO = "filters.info"