import { useEffect } from 'react'
import { connect } from 'react-redux';
import { StaticMap } from 'react-map-gl';
import DeckGL from '@deck.gl/react';
import { MAPBOX_ACCESS_TOKEN } from 'constants';
import jsCookie from 'js-cookie'

import {
  setSelectedAreaData,
  setSelectedAreaId,
  setHoveredAreaId,
} from 'store/actions';

import {
  TextLayer,
  PolygonLayer,
  PathLayer,
} from '@deck.gl/layers';

const Map = (props) => {
  const {
    areas,
    lakes,
    rivers,
    borders,
    activeAreaIds,
    selectedAreaData,
    selectedAreaId,
    hoveredAreaId,
    setSelectedAreaId,
    setHoveredAreaId,
    successedAnswers,
    failedAnswers,
    onSelect,
  } = props;

  useEffect(() => {
    if (selectedAreaId) {

      onSelect(selectedAreaId)
      setSelectedAreaId(null)
    }
  }, [selectedAreaId])

  const token = jsCookie.get('user_token')

  const getPolygon = d => d.contour

  const preparedAreas = areas.map((area) => ({
    ...area,
    hovered: hoveredAreaId === area.id,
    failed: failedAnswers.map(({ area_id }) => area_id).includes(area.id),
    successed: successedAnswers.map(({ area_id }) => area_id).includes(area.id),
    opponent: successedAnswers.filter(({ user_token }) => user_token !== token).map(({ area_id }) => area_id).includes(area.id),
  }))

  const areasLayer = new PolygonLayer({
    id: 'polygon-layer',
    data: preparedAreas,
    getFillColor: ({ id, tags, hovered, successed, opponent, failed }) => {
      let color = [185, 205, 185]

      if (activeAreaIds.includes(id)) {
        color = [215, 235, 215]
      }

      const waterTag = tags.find(({ attributes: { key, value }}) => key === 'water')

      if (successed) {
        color = [121, 179, 123]
      }

      if (opponent) {
        color = [101,163, 205]
      }

      if (failed) {
        color = [171, 129, 123]
      }

      if (waterTag) {
        color = [150, 200, 235]
      }

      if (hovered) {
        color = [color[0] + 20, color[1] + 20, color[2] + 20]
      }

      return color
    },
    getLineWidth: 700,
    getLineColor: [122, 122, 122],
    onHover: ({ object }) => {
      setHoveredAreaId(object?.id);
    },
    onClick: ({ object }) => {
      if (activeAreaIds.filter((id) => !successedAnswers.find(({ area_id }) => area_id === id)).includes(object?.id) && selectedAreaData?.id !== object.id) {
        setSelectedAreaId(object.id);
      } else {
        setSelectedAreaId(null);
      }
    },
    pickable: true,
    getPolygon,
    filled: true,
    lineJointRounded: true,
  });


  const preparedRivers = rivers.map((river) => ({
    ...river,
    // hovered: hoveredAreaId === river.id,
  }))

  const riverLayer = new PathLayer({
    id: 'path-layer',
    data: preparedRivers,
    pickable: true,
    widthScale: 100,
    capRounded: true,
    getPath: d => d.contour,
    getColor:  ({ id, tags, hovered, successed, opponent, failed }) => {
      let color = [80, 80, 205, 70]

      if (hovered) {
        color = [color[0], color[1], color[2] + 50]
      }

      return color
    },
    onHover: ({ object }) => {
      setHoveredAreaId(object?.id);
    },
    getWidth: ({ hovered }) => hovered ? 30 : 5
  });

  const borderLayer = new PolygonLayer({
    id: 'borders-layer',
    data: borders,
    getFillColor: ({ id, tags, hovered, successed, opponent, failed }) => {
      let color = [185, 205, 185, 0]

      return color
    },
    getLineColor: [80, 80, 80],
    getLineWidth: 1000,
    onHover: ({ object }) => {
      setHoveredAreaId(object?.id);
    },
    onClick: ({ object }) => {
      if (activeAreaIds.filter((id) => !successedAnswers.find(({ area_id }) => area_id === id)).includes(object?.id) && selectedAreaData?.id !== object.id) {
        setSelectedAreaId(object.id);
      } else {
        setSelectedAreaId(null);
      }
    },
    pickable: false,
    getPolygon,
    filled: true,
    lineJointRounded: true,
  });

  const titleLayer = new TextLayer({
    id: 'text-layer',
    data: preparedAreas.filter(({ successed, failed }) => successed || failed),
    pickable: false,
    getPosition: ({ contour }) => {
      const length = contour.length
      const longitude = contour.reduce((result, coords) => result + coords[0], 0) / length
      const latitude = contour.reduce((result, coords) => result + coords[1], 0) / length

      return [longitude, latitude]
    },
    getText: d => d.title,
    characterSet: 'auto',
    // getColor: [255, 255, 255],
    wordBreak: 'break-word',
    fontFamily: 'sans-serif',
    fontWeight: "bold",
    backgroundPadding: [2, 2, 2, 2],
    maxWidth: 400,
    // getSize: 10,
    getSize: 8,
    background: true,
  });

  const onMapClick = (event) => {
    const picked = event.object;

    if (!picked) {
      setSelectedAreaId(null);
    }
  };

  const initialViewState = {
    longitude: +localStorage.getItem('longitude') || 27.977712203885414,
    latitude: +localStorage.getItem('latitude') || 53.75785932811449,
    zoom: +localStorage.getItem('zoom') || 6.27,
    pitch: 0,
    bearing: 0
  };

  const layers = [
    areasLayer,
    borderLayer,
    riverLayer,
    titleLayer,
  ];

  const controller = { dragPan: true }

  const style = { zIndex: '1', overflow: 'hidden' }

  const getCursor = ({ isDragging }) => (isDragging ? 'grabbing' : (hoveredAreaId ? 'pointer' : 'grab'))

  return (
    <DeckGL
      onClick={onMapClick}
      initialViewState={initialViewState}
      layers={layers}
      controller={controller}
      style={style}
      getCursor={getCursor}
    >
      <StaticMap
        // mapStyle="mapbox://styles/mapbox/satellite-v9"
        mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
      />
    </DeckGL>
  )
}

export default connect(
  (state) => ({
    areas: state.main.areas,
    lakes: state.main.lakes,
    rivers: state.main.rivers,
    borders: state.main.borders,
    activeAreaIds: state.main.activeAreaIds,
    successedAnswers: state.main.successedAnswers,
    failedAnswers: state.main.failedAnswers,
    selectedAreaData: state.main.selectedAreaData,
    selectedAreaId: state.main.selectedAreaId,
    hoveredAreaId: state.main.hoveredAreaId,
    onSelect: state.main.onSelect,
    task: state.main.task,
  }),
  (dispatch) => ({
    setSelectedAreaData: (data) => dispatch(setSelectedAreaData(data)),
    setSelectedAreaId: (id) => dispatch(setSelectedAreaId(id)),
    setHoveredAreaId: (id) => dispatch(setHoveredAreaId(id)),
  })
)(Map);
