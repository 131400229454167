import {
  SET_ZOOM,
  ADD_AREA_DATA,
  REMOVE_AREA_DATA,
  SET_COMPANIES,
  ADD_POINT,
  ADD_POINT_DATA,
  RESET_POINT,
  SET_POINTS_DATA,
  UPDATE_AREA_DATA,
  SET_AREAS_DATA,
  SET_SELECTED_AREA_ID,
  SET_SELECTED_AREA_DATA,
  SET_HOVERED_AREA_ID,
  TOGGLE_MODE,
  SET_TASK,
  SET_SUCCESSED_ANSWERS,
  SET_FAILED_ANSWERS,
  SET_GAME,
  SET_AREAS,
  SET_LAKES,
  SET_RIVERS,
  SET_BORDERS,
  SET_ON_SELECT,
  SET_USERS,
} from 'constants';

export const setZoom = (zoom) => ({ type: SET_ZOOM, payload: zoom });

export const setAreasData = (areasData) => ({ type: SET_AREAS_DATA, payload: areasData });

export const setCompanies = (companies) => ({ type: SET_COMPANIES, payload: companies });

export const addPoint = (coordinates) => ({ type: ADD_POINT, payload: coordinates });

export const addPointData = (pointData) => ({ type: ADD_POINT_DATA, payload: pointData });

export const resetPoint = () => ({ type: RESET_POINT });

export const setPointsData = (pointsData) => ({ type: SET_POINTS_DATA, payload: pointsData });

export const addAreaData = (areaData) => ({ type: ADD_AREA_DATA, payload: areaData });

export const removeAreaData = (id) => ({ type: REMOVE_AREA_DATA, payload: id });

export const updateAreaData = (areaData) => ({ type: UPDATE_AREA_DATA, payload: areaData });

export const setSelectedAreaId = (id) => ({ type: SET_SELECTED_AREA_ID, payload: id });

export const setSelectedAreaData = (data) => ({ type: SET_SELECTED_AREA_DATA, payload: data });

export const setHoveredAreaId = (id) => ({ type: SET_HOVERED_AREA_ID, payload: id });

export const toggleMode = (mode) => ({ type: TOGGLE_MODE, payload: mode });

export const setTask = (task) => ({ type: SET_TASK, payload: task });

export const setSuccessedAnswers = (answers) => ({ type: SET_SUCCESSED_ANSWERS, payload: answers });

export const setFailedAnswers = (answers) => ({ type: SET_FAILED_ANSWERS, payload: answers });

export const setGame = (game) => ({ type: SET_GAME, payload: game });

export const setAreas = (areas) => ({ type: SET_AREAS, payload: areas });

export const setLakes = (lakes) => ({ type: SET_LAKES, payload: lakes });

export const setRivers = (rivers) => ({ type: SET_RIVERS, payload: rivers });

export const setBorders = (borders) => ({ type: SET_BORDERS, payload: borders });

export const setOnSelect = (onSelect) => ({ type: SET_ON_SELECT, payload: onSelect });

export const setUsers = (users) => ({ type: SET_USERS, payload: users });
