import {
  SET_ZOOM,
  ADD_AREA_DATA,
  ADD_POINT,
  RESET_POINT,
  ADD_POINT_DATA,
  REMOVE_AREA_DATA,
  UPDATE_AREA_DATA,
  SET_AREAS_DATA,
  SET_COMPANIES,
  SET_POINTS_DATA,
  SET_SELECTED_AREA_ID,
  SET_SELECTED_AREA_DATA,
  SET_HOVERED_AREA_ID,
  TOGGLE_MODE,
  SET_TASK,
  SET_SUCCESSED_ANSWERS,
  SET_FAILED_ANSWERS,
  SET_GAME,
  SET_AREAS,
  SET_LAKES,
  SET_RIVERS,
  SET_BORDERS,
  SET_ON_SELECT,
  SET_USERS,
} from 'constants';

export const mainReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_ZOOM: {
      return {
        ...state,
        zoom: action.payload,
      }
    }
    case ADD_AREA_DATA: {
      return {
        ...state,
        areasData: [...state.areasData, action.payload],
      }
    }
    case REMOVE_AREA_DATA: {
      return {
        ...state,
        areasData: state.areasData.filter(({ id }) => (id !== action.payload)),
      }
    }
    case UPDATE_AREA_DATA: {
      return {
        ...state,
        areasData: [
          ...state.areasData.filter(({ id }) => (id !== action.payload.id)),
          action.payload
        ],
      }
    }
    case SET_AREAS_DATA: {
      return {
        ...state,
        areasData: action.payload,
      }
    }
    case SET_COMPANIES: {
      return {
        ...state,
        companies: action.payload,
      }
    }
    case SET_POINTS_DATA: {
      return {
        ...state,
        pointsData: action.payload,
      }
    }
    case ADD_POINT: {
      return {
        ...state,
        pointCoordinates: action.payload,
      }
    }
    case ADD_POINT_DATA: {
      return {
        ...state,
        pointsData: [...state.pointsData, action.payload],
      }
    }
    case RESET_POINT: {
      return {
        ...state,
        pointCoordinates: null,
      }
    }
    case SET_SELECTED_AREA_ID: {
      return {
        ...state,
        selectedAreaId: action.payload,
      }
    }
    case SET_SELECTED_AREA_DATA: {
      return {
        ...state,
        selectedAreaData: action.payload,
      }
    }
    case SET_HOVERED_AREA_ID: {
      return {
        ...state,
        hoveredAreaId: action.payload,
      }
    }
    case TOGGLE_MODE: {
      return {
        ...state,
        mode: state.mode === action.payload ? null : action.payload,
      }
    }
    case SET_TASK: {
      return {
        ...state,
        task: action.payload
      }
    }
    case SET_SUCCESSED_ANSWERS: {
      return {
        ...state,
        successedAnswers: action.payload
      }
    }
    case SET_FAILED_ANSWERS: {
      return {
        ...state,
        failedAnswers: action.payload
      }
    }
    case SET_GAME: {
      return {
        ...state,
        activeAreaIds: action.payload.activeAreaIds,
        task: action.payload.task,
        successedAnswers: action.payload.successedAnswers,
        failedAnswers: action.payload.failedAnswers,
        users: action.payload.users ?? [],
      }
    }
    case SET_AREAS: {
      return {
        ...state,
        areas: action.payload,
      }
    }
    case SET_LAKES: {
      return {
        ...state,
        lakes: action.payload,
      }
    }
    case SET_RIVERS: {
      return {
        ...state,
        rivers: action.payload,
      }
    }
    case SET_BORDERS: {
      return {
        ...state,
        borders: action.payload,
      }
    }
    case SET_ON_SELECT: {
      return {
        ...state,
        onSelect: action.payload,
      }
    }
    case SET_USERS: {
      return {
        ...state,
        users: action.payload
      }
    }
  }

  return state;
};
