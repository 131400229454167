import axios from 'axios';

import { API_URL } from 'constants'

export function getTraining(id, level) {
  return axios.get(`${API_URL}/api/v1/trainings/${id}.json?level=${level}`, { withCredentials: true })
    .catch((response) => {
      console.log(response);

      return [];
    })
}