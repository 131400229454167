import { useEffect } from 'react'
import { connect } from 'react-redux';
import actionCable from 'actioncable'

import { Information } from 'components';
import { setTask, setSuccessedAnswers, setFailedAnswers, setOnSelect, setUsers } from 'store/actions';
import { loadGame, loadAreas } from 'store/thunks'
import { sendAnswer } from 'api/game'
import { useDrag } from 'hooks'

const CableApp = {}
CableApp.cable = actionCable.createConsumer('wss://games.urbanovich.org/cable')
// CableApp.cable = actionCable.createConsumer('ws://localhost:3000/cable')

const HomePage = (props) => {
  const {
    loadGame,
    setTask,
    setSuccessedAnswers,
    setFailedAnswers,
    setOnSelect,
    setUsers,
  } = props

  useDrag()

  useEffect(() => {
    const channel = CableApp.cable.subscriptions.create(
      'GameChannel',
      {
        connected: () => {
          console.log('connected')
        },
        disconnected: () => {
          console.log('disconnected')
        },
        received: (data) => {
          if (data.type === 'info') {
            setUsers(data.users)
          } else if (data.type === 'success') {
            setTask(data.task)
            setSuccessedAnswers(data.successedAnswers)
            setFailedAnswers([])
            setUsers(data.users)
          } else if (data.type === 'fail') {
            setFailedAnswers(data.failedAnswers)
          }
        }
      }
    )

    return () => {
      channel.unsubscribe()
    }
  }, [CableApp.cable.subscriptions])

  useEffect(() => {
    loadGame()
    setOnSelect(onSelect)
  }, [])

  const onSelect = (areaId) => sendAnswer(areaId)

  return (
    <Information/>
  )
}

export default connect(
  (state) => ({
    task: state.main.task,
    successedAnswers: state.main.successedAnswers,
    failedAnswers: state.main.failedAnswers,
  }),
  (dispatch) => ({
    setTask: (task) => dispatch(setTask(task)),
    setSuccessedAnswers: (answers) => dispatch(setSuccessedAnswers(answers)),
    setFailedAnswers: (answers) => dispatch(setFailedAnswers(answers)),
    loadGame: () => dispatch(loadGame()),
    loadAreas: () => dispatch(loadAreas()),
    setOnSelect: (onSelect) => dispatch(setOnSelect(onSelect)),
    setUsers: (users) => dispatch(setUsers(users)),
  })
)(HomePage);