import axios from 'axios';
import Cookies from 'js-cookie';

import { API_URL } from 'constants'

export function sendAnswer(id) {
  const token = Cookies.get('csrf_token');

  return axios.post(`${API_URL}/api/v1/games/answers.json`, { id }, { headers: { 'X-CSRF-TOKEN': token, withCredentials: true }})
    .then(response => response.data.data)
    .catch((response) => {
      console.log(response);

      return [];
    })
}
