import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { loadAreas } from 'store/thunks'
import './App.scss'

import { Map } from 'components';
import { HomePage, TrainingPage  } from 'pages'
import { useEffect } from 'react';

const App = (props) => {
  const { loadAreas } = props

  useEffect(() => {
    loadAreas()
  }, [])

  return (
    <div>
      <Map/>
      <Router>
        <Routes>
          <Route path="/trainings/:id" element={<TrainingPage />} />
          <Route path="/" element={<HomePage />} />
        </Routes>
      </Router>
    </div>
  )
}

export default connect(
  (state) => ({
  }),
  (dispatch) => ({
    loadAreas: () => dispatch(loadAreas()),
  })
)(App);