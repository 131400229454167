import { connect } from 'react-redux';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import cx from 'classnames'
import jsCookie from 'js-cookie';

const Container = styled.div`
  flex-direction: column;
  // gap: 16px;
  display: flex;
  position: absolute;
  z-index: 10;
  color: #333;
  margin: 16px;
  padding: 16px 24px;
  background-color: #fcfcfc;
  font-size: 48px;
  font-family: sans-serif;
  border-radius: 16px;

  .Task {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    display: flex;
  }

  .Title {
    font-weight: bold;
  }

  .Image {
    display: flex;
    // width: 140px;

    img {
      height: 160px;
    }
  }

  .actions {
    a {
      font-size: 30px;
      color: #333;
    }
  }

  .Users {
    flex-direction: column;
    display: flex;
    font-size: 18px;

    h3 {
      margin: 6px 0;
    }
  }

  .User {
    display: flex;
    justify-content: space-between;
    color: #008;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    div:not(:first-child) {
      text-align: right;
    }

    &_your {
      font-weight: bold;
      color: #080;
    }
  }

  @media screen and (max-width: 480px) {
    font-size: 20px;
    margin: 0;
    // display: block;
    width: calc(100% - 48px);
    padding: 16px 24px;
    border-radius: 0;

    .Image {
      img {
        height: 64px;
      }
    }

    .actions {
      a {
        font-size: 14px;
      }
    }
  }
`

const Information = ({ zoom, date, selectedAreaData, task, users }) => {
  const { pathname } = useLocation()
  const token = jsCookie.get('user_token')

  return (
    task ? (
      <Container>
        {/* <div>
          <Link to={`/trainings/${task.id - 1}`}>1=</Link>
          <Link to={`/trainings/${task.id + 1}`}>=1</Link>
        </div> */}
        <div className='Task'>
          <div>
            Клікніце на:
            <br/>
            <div className='Title'>
              {
                task.title
              }
            </div>
            <div className='actions'>
              {
                (pathname === '/') ? (
                  <Link to={`/trainings/${task.id}`}>Пачаць трэніроўку</Link>
                ) : (
                  <Link to={`/`}>Скончыць трэніроўку</Link>
                )
              }
            </div>
          </div>
          <div className='Image'>
            <img src={`/images/${task.title}.png`} alt=""/>
          </div>
        </div>
        {
          users.length ? (
            <div className="Users">
              <h3>Онлайн рэйтынг</h3>
              {
                users.map(({ name, token: userToken, countAnswers, accuracy }, index) => {
                  return (
                    <div className={cx('User', { User_your: userToken === token })} key={index}>
                      <div>{name ?? 'Невядомы'}</div>
                      <div>{`${accuracy}%`}</div>
                      <div>{countAnswers}</div>
                    </div>
                  )
                })
              }
            </div>
          ) : ''
        }
      </Container>
    ) : ''
  )
}

export default connect(
  (state) => ({
    zoom: state.main.zoom,
    selectedAreaData: state.main.selectedAreaData,
    task: state.main.task,
    users: state.main.users,
  }),
  (dispatch) => ({
  })
)(Information);
