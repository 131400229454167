import { useEffect, useState } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import jsCookie from 'js-cookie'

import { getTraining } from 'api/training'
import { Information, Map } from 'components';
import { setTask, setSuccessedAnswers, setFailedAnswers, setGame, setOnSelect } from 'store/actions';
import { sample } from 'lodash';

const TrainingPage = (props) => {
  const {
    areas,
    activeAreaIds,
    task,
    setTask,
    successedAnswers,
    failedAnswers,
    setSuccessedAnswers,
    setFailedAnswers,
    setGame,
    setOnSelect,
  } = props

  const location = useLocation()
  const navigate = useNavigate()
  const searchParams = new URLSearchParams(location.search)
  const token = jsCookie.get('user_token')

  const { id } = useParams();
  const [level, setLevel] = useState(+searchParams.get("level"))

  useEffect(() => {
    if (areas.length > 0) {
      loadGame()
    }
  }, [level, areas, id])

  const generateTask = (ids = activeAreaIds, excludedIds = successedAnswers.map(({ area_id }) => area_id)) => {
    const availableIds = ids.filter((id) => !excludedIds.includes(id))
    const sampledId = sample(availableIds)

    return areas.find(({ id }) => id === sampledId)
  }

  const loadGame = async () => {
    const response = await getTraining(id, level)
    const { activeAreaIds } = response.data
    const task = generateTask(activeAreaIds, [])

    setGame({ activeAreaIds, task, successedAnswers: [], failedAnswers: [] })
  }

  const onSelect = async (areaId) => {
    if (task.id === areaId) {
      const answers = [...successedAnswers, { area_id: areaId, user_token: token }]

      setSuccessedAnswers(answers)
      setFailedAnswers([])

      if (answers.length === activeAreaIds.length) {
        if (level < 2) {
          setLevel(level + 1)
        } else {
          navigate('/')
        }
      } else {
        setTask(generateTask(activeAreaIds, answers.map(({ area_id }) => area_id)))
      }
    } else {
      setFailedAnswers([...failedAnswers, { area_id: areaId }])
    }
  }

  useEffect(() => {
    setOnSelect(onSelect)
  }, [task, successedAnswers, failedAnswers])

  return (
    <div>
      <Information/>
    </div>
  )
}

export default connect(
  (state) => ({
    areas: state.main.areas,
    activeAreaIds: state.main.activeAreaIds,
    successedAnswers: state.main.successedAnswers,
    failedAnswers: state.main.failedAnswers,
    task: state.main.task,
  }),
  (dispatch) => ({
    setTask: (task) => dispatch(setTask(task)),
    setSuccessedAnswers: (answers) => dispatch(setSuccessedAnswers(answers)),
    setFailedAnswers: (answers) => dispatch(setFailedAnswers(answers)),
    setGame: (game) => dispatch(setGame(game)),
    setOnSelect: (onSelect) => dispatch(setOnSelect(onSelect)),
  })
)(TrainingPage);