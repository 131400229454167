import { client } from 'api/client';
import { areaToPolygonObject, pointToScatterplotObject } from 'services/deckGl';
import { setAreasData, setGame, setAreas, setLakes, setRivers, setBorders } from 'store/actions';
import Cookies from 'js-cookie';

import { API_URL } from 'constants'

export const loadAreasData = () => (dispatch) => {
  client.get(`${API_URL}/api/v1/areas.json`, {
      // params: {
      //   date: localStorage.getItem('date'),
      //   zoom: localStorage.getItem('zoom'),
      //   startDate: localStorage.getItem('filters.startDate') === 'true'
      // },
      withCredentials: true
    })
    .then((response) => {
      dispatch(setAreasData(response.data.data.map(areaToPolygonObject)));
    })
    .catch((error) => {
      console.log(error)
    });
}

export const loadGame = () => (dispatch) => {
  client.get(`${API_URL}/api/v1/games.json`, { withCredentials: true })
    .then((response) => {
      return response.data
    })
    .then((response) => {
      dispatch(setGame(response));
    })
    .catch((error) => {
      console.log(error)
    });
}

export const loadAreas = () => (dispatch) => {
  client.get(`${API_URL}/api/v1/areas.json`, { withCredentials: true })
    .then((response) => response.data)
    .then((response) => {
      // response.data.filter(({ attributes: { tags }}) => tags.find(({ attributes: { key, value }}) => { console.log(key, value); return key === 'admin_level' && value === '6' }))
      // console.log()

      dispatch(setAreas(response.data.filter(({ attributes: { tags }}) => tags.find(({ attributes: { key, value }}) =>  key === 'admin_level' && value === '6')).map(areaToPolygonObject)));
      dispatch(setRivers(response.data.filter(({ attributes: { tags }}) => tags.find(({ attributes: { key }}) => key === 'water')).map(areaToPolygonObject)));
      dispatch(setBorders(response.data.filter(({ attributes: { tags }}) => tags.find(({ attributes: { key, value }}) => key === 'admin_level' && value === '4')).map(areaToPolygonObject)));
    })
    .catch((error) => {
      console.log(error)
    });
}