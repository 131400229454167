import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import reducer from './reducers';

const initialState = {
  main: {
    areas: [],
    lakes: [],
    rivers: [],
    borders: [],
    activeAreaIds: [],
    selectedAreaData: null,
    hoveredAreaId: null,
    task: null,
    successedAnswers: [],
    failedAnswers: [],
    onSelect: () => {},
    users: [],
  }
};

const store = createStore(reducer, initialState, applyMiddleware(thunk));

export default store;
